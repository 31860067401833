import React from "react";
import { graphql, PageProps } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

interface GalleryPageProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        siteName: string;
      };
    };
  };
}

export const pageQuery = graphql`
  query GalleryQuery {
    site {
      siteMetadata {
        siteName
      }
    }
  }
`;

const GalleryPage: React.FC<GalleryPageProps> = props => {
  const { t } = useTranslation("navigation");
  const { siteName } = props.data.site.siteMetadata;
  return (
    <main className="full-centered">
      <h1>{t("gallery")}</h1>
      <p>
        This site is named <strong>{siteName}</strong>
      </p>
    </main>
  );
};

export default GalleryPage;
